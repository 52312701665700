<template>
    <div v-if="posInfo && posInfo.flightSet && posInfo.flightSet.exists">
        <pre> {{ JSON.stringify(posInfo.flightSet.data, null, 4) }} </pre>
    </div>
</template>
<script>
export default {
    name: "DetailPosFlight",
    data: () => {
        return {
            posInfo: null

        };
    },
    created() {
        this.axios.get("/api/pos/info").then(resp => { this.posInfo = resp.data; });
    },
}
</script>
